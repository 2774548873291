import { useQuery } from '@tanstack/react-query';
import { type Filters, type FetchResponse } from "..//types/types";

function buildUrl(filters: Filters): string {
  const url = new URL('/map/properties', window.location.origin);
  Object.entries(filters).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      url.searchParams.append(key, String(value));
    }
  });
  return url.toString();
}

async function fetchProperties(filters: Filters): Promise<FetchResponse> {

  if (!filters.zoom || filters.zoom < 10) {
    return {
      properties: [],
      meta_data: { total_count: 0 },
    };
  }

  try {
    const url = buildUrl(filters);

    const response = await fetch(url, {
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
      throw new Error(`HTTP error. Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('[useProperties.fetchProperties] Error en fetch:', error);
    throw error;
  }
}

export function useProperties(filters: Filters) {

  const { data, isLoading, isError, error } = useQuery<FetchResponse>({
    queryKey: ['properties', filters],
    queryFn: async () => await fetchProperties(filters),
  });

  const properties = data?.properties || [];
  const meta = data?.meta_data || { total_count: 0 };

  return { properties, meta, isLoading, isError, error, };
}