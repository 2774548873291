import React from 'react';

interface Props {
  options: any[];
  name: string;
  onClick: (name: string, value: string) => void;
  label: string;
  selected: any;
  labelClassName?: string;
}

const HorizontalRadio: React.FunctionComponent<Props> = ({ label, name, onClick, selected, labelClassName }) => {
  const opts = [
    { label: 'Any', value: 0 },
    { label: '1+', value: 1 },
    { label: '2+', value: 2 },
    { label: '3+', value: 3 },
    { label: '4+', value: 4 },
    { label: '5+', value: 5 }
  ];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onClick(event.target.name, event.target.value);
  };

  const inputs = opts.map((option) => {
    const checked = option.value == selected;
    const selectedClass = checked ? ' bg-primary text-white' : '';
    return (
      <label
        className={`p-2 font-bold border-slate-300 border-2 rounded-lg${selectedClass} ${labelClassName || ''}`}
        key={`${label}-${option.value}`}
      >
        {option.label}
        <input name={name} className="hidden" onChange={onChange} type="radio" value={option.value} checked={checked} />
      </label>
    );
  });

  return (
    <div className="flex flex-col">
      <p className={`font-semibold ${labelClassName || ''}`}>{label}</p>
      <div className="flex flex-wrap justify-left gap-2">{inputs}</div>
    </div>
  );
};

export default HorizontalRadio;
