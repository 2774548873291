import React, { forwardRef, useRef, useContext, useImperativeHandle, createContext } from 'react';
import useOnClickOutside from '../hooks/onClickOutside';
import { PropertyContext } from '../search/context/property-context-v2';
import Suggestion from './components/suggestion';
import { CloseIcon, SearchIcon } from '../components/icons';
import { usePlaceSearch } from '../hooks/usePlaceSearch';

const PlaceHolderContext = createContext({
  setFilters: () => { },
  filters: {}
});

interface Props {
  label?: string;
  redirect?: boolean;
  place?: string | null;
  placeholder: any;
  baseUrl?: string;
  onPlaceSelected?: (data: {
    lat: string;
    lng: string;
    city: string;
    state: string;
  }) => void;
  updateFilters?: boolean;
  redirectParams?: string[];
}

export interface PlaceSearchRef {
  manualSearch: () => void;
}

const PlaceSearch = forwardRef<PlaceSearchRef, Props>(function PlaceSearchBase(
  {
    onPlaceSelected,
    redirect = true,
    place,
    placeholder,
    baseUrl,
    updateFilters = false,
    redirectParams
  },
  ref
) {
  const contextToUse = updateFilters ? PropertyContext : PlaceHolderContext;
  const { filters, setFilters } = useContext(contextToUse);
  const listRef = useRef<HTMLDivElement>(null);

  const {
    search,
    setSearch,
    places,
    isLoading,
    manualSearch,
    resetSearch,
    placeSelectedCallback,
    clearSearch,
    isDropdownVisible,
    setIsDropdownVisible
  } = usePlaceSearch({
    initialPlace: place,
    baseUrl,
    filters,
    setFilters,
    onPlaceSelected,
    redirect,
    redirectParams
  });

  useOnClickOutside(listRef, () => {
    if (!search) {
      clearSearch();
    }
    setIsDropdownVisible(false);
  });

  useImperativeHandle(ref, () => ({
    manualSearch
  }));

  const handleInputFocus = () => {
    if (search) {
      setIsDropdownVisible(true);
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      manualSearch();
    }
  };

  return (
    <div className="relative w-full">
      <div className="relative h-full">
        <input 
          id="place-search" 
          type="text" 
          placeholder="" 
          className="px-2 py-2 border h-full rounded-lg xl:rounded-l-lg xl:rounded-r-none focus:outline-none focus:border-primary w-[80%] min-w-[260px] sm:min-w-[460px] md:min-w-[600px] xl:min-w-[500px] max-w-[800px]"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
        />

        {!search && (
          <div className="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none text-gray-400">
            {placeholder}
          </div>
        )}

        <button
          type="button"
          className="absolute right-4 inset-y-0 flex items-center text-gray-600 xl:hidden"
          onClick={manualSearch}
        >
          <SearchIcon />
        </button>

        {updateFilters && (
          <button
            type="button"
            className="absolute right-4 inset-y-0 hidden xl:flex items-center text-gray-600 overflow-visible"
            onClick={resetSearch}
          >
            <CloseIcon />
          </button>
        )}
      </div>

      <div ref={listRef} className="mt-1 absolute z-[9999] safari-zindex-fix">
        <ul className={`border-solid bg-white border-primary border-2 rounded-lg ${(isDropdownVisible && (places.length > 0 || isLoading)) ? 'visible' : 'hidden'}`}>
          {isLoading ? (
            <li className="px-4 py-3 text-gray-500 text-center">Searching...</li>
          ) : places.length > 0 ? (
            places.map((p) => (
              <Suggestion 
                key={p.mapbox_id} 
                suggestion={p} 
                onSelect={placeSelectedCallback} 
              />
            ))
          ) : search ? (
            <li className="px-4 py-3 text-gray-500 text-center">No locations found</li>
          ) : null}
        </ul>
      </div>
    </div>
  );
});

PlaceSearch.displayName = 'PlaceSearch';

export default PlaceSearch;