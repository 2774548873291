import React from "react";
import ListingNew from "./ListingV2";
import { type Property } from "../../types/types";

interface PropertyListProps {
  properties: Property[];
}

export const PropertyList: React.FC<PropertyListProps> = ({ properties }) => {
  return (
    <>
      {properties.map((property: Property) => (
        <ListingNew key={property.id} property={property} />
      ))}
    </>
  );
};
