import React, { useEffect, useRef, useContext } from 'react';
import { PropertyContext } from '../search/context/property-context-v2';
import { renderToString } from 'react-dom/server';
import mapboxgl from 'mapbox-gl';
import PopupContent from '../search/components/PopupContent';
import OvalMarker from '../components/icons/OvalMarker';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../search/styles/map.css';

interface Property {
  long: number;
  lat: number;
  selling_price: string;
  [key: string]: any;
}

interface Filters {
  zoom: number;
  ne: string;
  sw: string;
  city: string;
  state: string;
  [key: string]: any;
}

interface Meta {
  boundary: GeoJSON.Geometry;
}

interface PropertyContextType {
  properties: Property[];
  filters: Filters;
  setFilters: (filters: Filters) => void;
  meta: Meta;
}

interface Center {
  lat: number;
  lng: number;
}

const useMap = (center: Center | null) => {
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapboxToken =
    'pk.eyJ1IjoibWNpb2NjYTg5IiwiYSI6ImNsODY5a21sNjB0OXEzbnQ5bGxxNnhnangifQ.vrKWYQ8gm_PKGjaypu6K2A';
  const { properties, filters, setFilters, meta } = useContext(PropertyContext) as PropertyContextType;
  const primaryColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--primary-color')
    .trim();

  const removePlaceLayer = (): void => {
    if (mapRef.current.getLayer('outline')) {
      mapRef.current.removeLayer('outline');
    }
    if (mapRef.current.getSource('place')) {
      mapRef.current.removeSource('place');
    }
  };

  useEffect(() => {
    if (mapRef.current?.isStyleLoaded() && meta.boundary) {
      removePlaceLayer();

      mapRef.current.addSource('place', {
        type: 'geojson',
        data: { type: 'Feature', geometry: meta.boundary },
      });

      mapRef.current.addLayer({
        id: 'outline',
        type: 'line',
        source: 'place',
        layout: {},
        paint: { 'line-color': primaryColor, 'line-width': 3 },
      });
    }
  }, [meta, mapRef.current]);

  useEffect(() => {
    if (mapRef.current?.loaded()) {
      mapRef.current.setZoom(filters.zoom);
    }
  }, [filters.zoom]);

  useEffect(() => {
    mapboxgl.accessToken = mapboxToken;
    const ovalSvg = renderToString(<OvalMarker primaryColor={primaryColor} />);
    const ovalUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(ovalSvg)}`;

    const map = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: center || [-73.064034, 41.230698],
      zoom: 12,
      dragPan: true,
    });

    mapRef.current = map;

    map.on('load', () => {
      const img = new Image();
      img.onload = () => {
        map.addImage('oval-marker', img);

        map.addSource('properties', {
          type: 'geojson',
          data: getPropertiesGeoJSON(),
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });

        addClusterLayers();
        addControls();
        addMapEventListeners();
        handleMapMoveEnd();
      };
      img.src = ovalUrl;
    });

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!filters.city && mapRef.current) {
      removePlaceLayer();
    }

    mapRef.current?.on('moveend', handleMapMoveEnd);

    return () => {
      mapRef.current?.off('moveend', handleMapMoveEnd);
    };
  }, [filters]);

  useEffect(() => {
    if (mapRef.current && center) {
      mapRef.current.panTo(center);
    }
  }, [center]);

  useEffect(() => {
    const updatePropertiesData = () => {
      if (mapRef.current && mapRef.current.getSource('properties')) {
        const source = mapRef.current.getSource('properties') as mapboxgl.GeoJSONSource;
        source.setData(getPropertiesGeoJSON());
      }
    };

    if (mapRef.current) {
      if (mapRef.current.isStyleLoaded()) {
        updatePropertiesData();
      } else {
        mapRef.current.on('idle', updatePropertiesData);
      }
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.off('idle', updatePropertiesData);
      }
    };
  }, [properties]);

  const getPlaceFilter = () => {
    const params = new URLSearchParams(window.location.search);
    let state = params.get('state') || '';
    let city = params.get('city') || '';
    if (city === 'null') city = '';
    if (state === 'null') state = '';
    return { city, state };
  };

  const handleMapMoveEnd = () => {
    if (mapRef.current) {
      const zoom = mapRef.current.getZoom();
      const bounds = mapRef.current.getBounds();
      const sw = `${bounds.getSouthWest().lng},${bounds.getSouthWest().lat}`;
      const ne = `${bounds.getNorthEast().lng},${bounds.getNorthEast().lat}`;
      const { city, state } = getPlaceFilter();
      setFilters((prevFilters) => ({ ...prevFilters, ne, sw, zoom, city, state }));
    }
  };

  const addControls = () => {
    const navControl = new mapboxgl.NavigationControl({ showCompass: false, showZoom: true });
    mapRef.current.addControl(navControl, 'top-right');
  };

  const getPropertiesGeoJSON = () => ({
    type: 'FeatureCollection',
    features: properties.map((property) => ({
      type: 'Feature',
      geometry: { type: 'Point', coordinates: [property.long, property.lat] },
      properties: { ...property },
    })),
  });

  const addClusterLayers = () => {
    if (!mapRef.current) return;

    mapRef.current.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'properties',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': primaryColor,
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
      },
    });

    mapRef.current.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'properties',
      filter: ['has', 'point_count'],
      layout: { 'text-field': '{point_count_abbreviated}', 'text-size': 12 },
      paint: { 'text-color': '#fff' },
    });

    mapRef.current.addLayer({
      id: 'unclustered-point',
      type: 'symbol',
      source: 'properties',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': 'oval-marker',
        'icon-size': 1,
        'icon-allow-overlap': true,
        'icon-anchor': 'center'
      }
    });

    mapRef.current.addLayer({
      id: 'unclustered-point-label',
      type: 'symbol',
      source: 'properties',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'text-field': ['concat', ['get', 'selling_price']],
        'text-size': 13,
        'text-allow-overlap': true,
        'text-anchor': 'center',
        'text-offset': [0, 0]
      },
      paint: {
        'text-color': primaryColor,
        'text-halo-color': '#ffffff',
        'text-halo-width': 2,
      },
    });
  };

  const addMapEventListeners = () => {
    if (!mapRef.current) return;

    mapRef.current.on('click', 'clusters', (e: { point: any; }) => {
      const features = mapRef.current.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      const clusterId = features[0].properties.cluster_id;
      mapRef.current.getSource('properties').getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
        if (err) return;
        mapRef.current.easeTo({ center: features[0].geometry.coordinates, zoom });
      });
    });

    mapRef.current.on('click', 'unclustered-point', (e: { features: Array<{ geometry: any; properties: any }> }) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const property = e.features[0].properties;

      new mapboxgl.Popup({ 
        closeButton: false,
        className: 'property-popup',
        offset: [0, -15],
        maxWidth: '240px'
      })
      .setLngLat(coordinates)
      .setHTML(renderToString(<PopupContent property={property} primaryColor={primaryColor} />))
      .addTo(mapRef.current);

      if (window.innerWidth < 640 && mapRef.current) {
        const offsetY = 220;
        const point = mapRef.current.project(coordinates);
        const newPoint = { x: point.x, y: point.y + offsetY };
        const newCenter = mapRef.current.unproject(newPoint);
        mapRef.current.easeTo({ center: newCenter, duration: 300 });
      }
    });

    mapRef.current.on('mouseenter', 'clusters', () => {
      mapRef.current.getCanvas().style.cursor = 'pointer';
    });
    mapRef.current.on('mouseleave', 'clusters', () => {
      mapRef.current.getCanvas().style.cursor = '';
    });
    mapRef.current.on('mouseenter', 'unclustered-point', () => {
      mapRef.current.getCanvas().style.cursor = 'pointer';
    });
    mapRef.current.on('mouseleave', 'unclustered-point', () => {
      mapRef.current.getCanvas().style.cursor = '';
    });
  };

  return mapRef.current;
};

export default useMap;
