import { useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import retrievePlaces from './retrievePlace';

interface PlaceSearchProps {
  initialPlace?: string | null;
  baseUrl?: string;
  filters: any;
  setFilters: (filters: any) => void;
  onPlaceSelected?: (data: {
    lat: string;
    lng: string;
    city: string;
    state: string;
  }) => void;
  redirect?: boolean;
  redirectParams?: string[];
}

export const usePlaceSearch = ({
  initialPlace,
  baseUrl,
  filters,
  setFilters,
  onPlaceSelected,
  redirect,
  redirectParams
}: PlaceSearchProps) => {
  const [search, setSearch] = useState(initialPlace || '');
  const [searchActive, setSearchActive] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const buildRequestUrl = useCallback((searchTerm: string) => {
    const url = new URL(window.location.href.toLowerCase());
    const lat = url.searchParams.get('lat');
    const lng = url.searchParams.get('lng');
    let requestUrl = `${baseUrl || ''}/place_suggestions?query=${searchTerm}`;
    if (lat && lng) {
      requestUrl += `&lat=${lat}&lng=${lng}`;
    }
    return requestUrl;
  }, [baseUrl]);

  const fetchPlaces = async (searchTerm: string) => {
    if (!searchTerm) return { suggestions: [] };

    try {
      const response = await fetch(buildRequestUrl(searchTerm));
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    } catch (error) {
      console.error('Error fetching places:', error);
      return { suggestions: [] };
    }
  };

  const { data: placesData = { suggestions: [] }, isLoading } = useQuery({
    queryKey: ['places', search],
    queryFn: () => fetchPlaces(search),
    enabled: Boolean(search && searchActive && isDropdownVisible),
    staleTime: 0,
    cacheTime: 0,
    retry: false
  });

  const setZoom = (featureType: string) => {
    const newFilters = { ...filters };
    switch (featureType) {
      case 'address':
      case 'street':
        newFilters.zoom = 14;
        break;
      case 'neighborhood':
        newFilters.zoom = 12;
        break;
      default:
        newFilters.zoom = 10;
        break;
    }
    setFilters(newFilters);
  };

  const logSearch = async (query: string | Blob, name: string | Blob) => {
    try {
      const csrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content') || '';
      const csrfParam = document.querySelector('meta[name=csrf-param]')?.getAttribute('content') || '';
      const body = new FormData();
      body.append(csrfParam, csrfToken);
      body.append('query', query);
      body.append('name', name);

      await fetch(`/search_history/log_search`, { method: 'POST', body });
    } catch (e) {
      console.error('Error logging search', e);
    }
  };

  const placeSelectedCallback = async (mapboxId: any) => {
    const foundPlace = await retrievePlaces(mapboxId);
    setZoom(foundPlace.featureType);
    setSearchActive(false);
    setIsDropdownVisible(false);

    const newSearchText = foundPlace.city === null
      ? foundPlace.state
      : `${foundPlace.city}, ${foundPlace.state}`;
    setSearch(newSearchText);

    logSearch(JSON.stringify(filters), newSearchText);

    if (redirect) {
      const formattedRedirectParams = (redirectParams || []).join('&');
      const url = `${baseUrl || ''}/search?lat=${foundPlace.lat}&lng=${foundPlace.lng}&state=${foundPlace.state}&city=${foundPlace.city}&${formattedRedirectParams}&zoom=${filters.zoom}`;
      window.location.href = url;
    } else {
      onPlaceSelected?.(foundPlace);
    }
  };

  const resetSearch = () => {
    setSearch('');
    setSearchActive(false);
    setIsDropdownVisible(false);
    const newFilters = { ...filters };
    delete newFilters.city;
    delete newFilters.state;

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('city');
    currentUrl.searchParams.delete('state');
    window.history.replaceState({}, '', currentUrl.href);

    setFilters(newFilters);
  };

  const manualSearch = async () => {
    if (!search) return;
    setSearchActive(true);
    const results = await fetchPlaces(search);
    if (results?.suggestions?.length) {
      await placeSelectedCallback(results.suggestions[0].mapbox_id);
    }
  };

  const handleInputChange = (value: string) => {
    setSearch(value);
    setSearchActive(true);
    setIsDropdownVisible(true);
  };

  const clearSearch = () => {
    setSearchActive(false);
    setIsDropdownVisible(false);
  };

  return {
    search,
    setSearch: handleInputChange,
    places: placesData.suggestions || [],
    isLoading,
    manualSearch,
    resetSearch,
    placeSelectedCallback,
    clearSearch,
    isDropdownVisible,
    setIsDropdownVisible
  };
};