import React from 'react';

interface Props {
  name_prefix: string;
  label: string;
  onChange: (name: string, value: any) => void;
  rangeHigh: any;
  rangeLow: any;
  labelClassName?: string;
}

const RangeInput: React.FunctionComponent<Props> = ({ 
  label, 
  name_prefix, 
  onChange, 
  rangeHigh, 
  rangeLow, 
  labelClassName
}) => {
  const optionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <div className="flex flex-col">
      <label className={`text-black font-semibold ${labelClassName || ''}`}>{label}</label>
      <div className="flex mt-2">
        <input
          className="rounded-lg mobile:w-40"
          type="text"
          value={rangeLow || ''}
          onChange={optionChanged}
          name={`${name_prefix}_low`}
          placeholder="No min"
        />
        <span>&nbsp;-&nbsp;</span>
        <input
          className="rounded-lg mobile:w-40"
          type="text"
          value={rangeHigh || ''}
          onChange={optionChanged}
          name={`${name_prefix}_high`}
          placeholder="No max"
        />
      </div>
    </div>
  );
};

export default RangeInput;
