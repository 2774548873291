import React from 'react';

interface ForSaleProps {
  width?: number;
  height?: number;
  className?: string;
}

const ForSale: React.FC<ForSaleProps> = ({ width = 24, height = 23, className }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 24 23" 
      className={className}
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd'
      }}
    >
      <g>
        <path 
          style={{ opacity: 0.758 }} 
          fill="currentColor" 
          d="M2.5,-0.5C3.5,-0.5 4.5,-0.5 5.5,-0.5C11.0738,1.45334 17.0738,2.12 23.5,1.5C23.5,2.16667 23.5,2.83333 23.5,3.5C23.1667,3.5 22.8333,3.5 22.5,3.5C16.9629,2.6145 10.9629,2.28117 4.5,2.5C4.5,9.16667 4.5,15.8333 4.5,22.5C4.16667,22.5 3.83333,22.5 3.5,22.5C3.66629,15.4921 3.49962,8.49207 3,1.5C1.58296,2.07794 0.749624,3.07794 0.5,4.5C0.166667,4.5 -0.166667,4.5 -0.5,4.5C-0.5,3.5 -0.5,2.5 -0.5,1.5C1.02694,1.57298 2.02694,0.906316 2.5,-0.5Z"
        />
      </g>
      <g>
        <path 
          style={{ opacity: 0.727 }} 
          fill="currentColor" 
          d="M22.5,3.5C22.5,4.16667 22.1667,4.5 21.5,4.5C16.2599,5.44913 10.9265,5.78246 5.5,5.5C5.5,8.5 5.5,11.5 5.5,14.5C5.5,17.1667 5.5,19.8333 5.5,22.5C5.16667,22.5 4.83333,22.5 4.5,22.5C4.5,15.8333 4.5,9.16667 4.5,2.5C10.9629,2.28117 16.9629,2.6145 22.5,3.5Z"
        />
      </g>
      <g>
        <path 
          style={{ opacity: 0.694 }} 
          fill="currentColor" 
          d="M3.5,22.5C3.16667,22.5 2.83333,22.5 2.5,22.5C3.12,16.0738 2.45334,10.0738 0.5,4.5C0.749624,3.07794 1.58296,2.07794 3,1.5C3.49962,8.49207 3.66629,15.4921 3.5,22.5Z"
        />
      </g>
      <g>
        <path 
          style={{ opacity: 0.649 }} 
          fill="currentColor" 
          d="M21.5,4.5C21.5,8.16667 21.5,11.8333 21.5,15.5C16.0735,15.7825 10.7401,15.4491 5.5,14.5C5.5,11.5 5.5,8.5 5.5,5.5C10.9265,5.78246 16.2599,5.44913 21.5,4.5ZM7.5,6.5C11.5,6.5 15.5,6.5 19.5,6.5C19.5,8.83333 19.5,11.1667 19.5,13.5C15.5,13.5 11.5,13.5 7.5,13.5C7.5,11.1667 7.5,8.83333 7.5,6.5Z"
        />
      </g>
    </svg>
  );
};

export default ForSale;