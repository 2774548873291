import React from 'react';
import logo from '../../../images/logo/otto_icon_indicator.png';

const OttoBannerNew = () => (
  <div className="flex bg-primary text-white justify-start items-center py-2.5 px-4 text-sm font-sourcesans whitespace-nowrap rounded-lg">
    <img src={logo} alt="otto homes logo" className="h-6 mr-2" />
    <p>OTTO Exclusive</p>
  </div>
);

export default OttoBannerNew;
