import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropertyContextProvider } from '../context/property-context-v2';
import SearchBar from './SearchBar';
import Listings from './Listings-v2';
import Map from './Map-v2';
import BottomBar from './BottomBar';
import '../styles/searchbar.css';
import HomeTitle from './HomeTitle';

const queryClient = new QueryClient();

export const SearchPageV2 = () => {
  const [center, setCenter] = useState<[number, number] | null>(null);
  const [place, setPlace] = useState<{ city: string; state: string } | null>(null);
  const [mapVisible, setMapVisibility] = useState(true);

  const onPlaceSelected = ({ lat, lng, city, state }) => {
    createURL({ lat, lng, city, state });
    setCenter([parseFloat(lng), parseFloat(lat)]);
    setPlace({ city, state });
  };

  const createURL = ({ lat, lng, city, state }) => {
    const baseUrl = window.location.href.split('?')[0];
    const formattedUrl = `${baseUrl}?lat=${lat}&lng=${lng}&state=${state}&city=${city}`;
    window.history.pushState({}, '', formattedUrl);
  };

  useEffect(() => {
    const url = new URL(window.location.href.toLowerCase());
    const lat = url.searchParams.get('lat');
    const lng = url.searchParams.get('lng');
    const city = url.searchParams.get('city');
    const state = url.searchParams.get('state');

    if (lat && lng) {
      setCenter([parseFloat(lng), parseFloat(lat)]);
    } else {
      setCenter(null);
    }

    if (city && state) {
      setPlace({ city, state });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <PropertyContextProvider>
        <div className="sticky pt-5 top-20 bg-white z-30">
          <SearchBar
            onPlaceSelected={onPlaceSelected}
            place={place}
            mapVisible={mapVisible}
            toggleMapVisibility={() => setMapVisibility(!mapVisible)}
          />
        </div>
        <div className="relative sm:px-0">
          <HomeTitle />
          <div className="flex relative rounded-lg xl:pl-4 xl:h-[calc(100vh-225px)]">
            <Listings visible={!mapVisible} />
            <Map visible={mapVisible} center={center} />
          </div>
          <BottomBar />
        </div>
        <ToastContainer />
      </PropertyContextProvider>
    </QueryClientProvider>
  );
};

export default SearchPageV2;