import React, { useRef, useEffect, useState, useCallback } from 'react';
import PlaceSearch, { type PlaceSearchRef } from '../../place-search/place-search-v2';
import PreFilters from './PreFilters';
import Filters from './Filters-v2';
import MobileControlsV2 from './MobileControlsV2';
import { usePropertyContext } from '../context/property-context-v2';
import { FilterIcon, SearchIcon } from '../../components/icons';
import { toast } from 'react-toastify';
import { useSession } from '../../hooks/useSession';

interface SearchBarProps {
  onPlaceSelected: (location: { lat: string; lng: string; city: string; state: string }) => void;
  place: { city: string; state: string } | null;
  mapVisible: boolean;
  toggleMapVisibility: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onPlaceSelected, place, mapVisible, toggleMapVisibility }) => {
  const { filters, setFilters } = usePropertyContext();
  const [showPriceDropdown, setShowPriceDropdown] = useState(false);
  const [showSizeDropdown, setShowSizeDropdown] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const priceDropdownRef = useRef<HTMLDivElement>(null);
  const sizeDropdownRef = useRef<HTMLDivElement>(null);
  const advancedFiltersButtonRef = useRef<HTMLButtonElement>(null);
  const placeSearchRef = useRef<PlaceSearchRef>(null);

  const { isLoggedIn } = useSession();

  const sizeOptions = [
    { label: 'Any Size', value: 0 },
    { label: '500+ sqft', value: 500 },
    { label: '1000+ sqft', value: 1000 },
    { label: '1500+ sqft', value: 1500 },
    { label: '2000+ sqft', value: 2000 },
    { label: '2500+ sqft', value: 2500 },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        priceDropdownRef.current &&
        !priceDropdownRef.current.contains(event.target as Node)
      ) {
        setShowPriceDropdown(false);
      }
      if (
        sizeDropdownRef.current &&
        !sizeDropdownRef.current.contains(event.target as Node)
      ) {
        setShowSizeDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePriceChange = useCallback(
    (type: 'price_low' | 'price_high', value: string) => {
      const numValue = value ? parseInt(value) : undefined;
      setFilters((prev: any) => ({ ...prev, [type]: numValue }));
    },
    [setFilters]
  );

  const handleSizeChange = useCallback(
    (value: number) => {
      setFilters((prev: any) => ({ ...prev, sqft_low: value }));
      setShowSizeDropdown(false);
    },
    [setFilters]
  );

  const handleMyBoardsClick = async () => {
    if (isLoggedIn) {
      const response = await fetch('/board_properties/new', {
        headers: { Accept: 'text/vnd.turbo-stream.html' },
      });

      if (response.ok) {
        const html = await response.text();
        const turboFrame = document.getElementById('modal');
        if (turboFrame) {
          turboFrame.innerHTML = html;
        }
      }
    } else {
      window.location.href = '/users/sign_in';
    }
  };

  const handleSaveSearch = async () => {
    if (!isLoggedIn) {
      window.location.href = '/users/sign_in';
      return;
    }
    try {
      const res = await fetch('/search_history/log_search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token':
            document.querySelector('[name="csrf-token"]')?.getAttribute('content') || '',
        },
        body: JSON.stringify({
          query: JSON.stringify(filters),
          name: place ? `${place.city}, ${place.state}` : 'Saved Search',
        }),
      });

      if (res.ok) {
        toast.success('Search saved successfully');
      } else {
        toast.error('Error saving search');
      }
    } catch (error) {
      toast.error('Error saving search');
    }
  };

  const handleSearchClick = () => {
    placeSearchRef.current?.manualSearch();
  };

  return (
    <div className="w-full bg-white shadow-lg flex items-center pb-4">
      <div className="flex items-center w-full mx-2 xl:mx-10 sm:justify-center xl:justify-between">
        <div className="hidden xl:flex xl:flex-1 text-left h-10">
          <PreFilters />
        </div>
        <div className="flex flex-2 text-center h-10">
          <div className="flex h-full rounded-lg xl:rounded-l-lg">
            <PlaceSearch
              ref={placeSearchRef}
              onPlaceSelected={onPlaceSelected}
              redirect={false}
              placeholder={
                <span className="text-xs text-left flex">
                  Where <br /> Search towns & areas
                </span>
              }
              updateFilters={true}
              place={place}
            />
          </div>
          <div className="xl:hidden ml-2 flex items-center">
            <MobileControlsV2
              toggleMapVisibility={toggleMapVisibility}
              mapVisible={mapVisible}
            />
          </div>
          <div className="hidden xl:flex border border-l-0 rounded-r-lg h-full">
            <div className="relative h-full flex items-center" ref={priceDropdownRef}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPriceDropdown(!showPriceDropdown);
                  setShowSizeDropdown(false);
                }}
                className="h-full px-2 flex items-center hover:bg-gray-50 border-r"
              >
                <span className="text-xs">
                  {filters.price_low || filters.price_high
                    ? `${filters.price_low || '0'} - ${filters.price_high || '∞'}`
                    : 'Any Budget'}
                </span>
              </button>

              {showPriceDropdown && (
                <div className="absolute top-full left-0 mt-1 w-72 bg-white border rounded-lg shadow-lg p-4 z-50">
                  <div className="flex space-x-2">
                    <input
                      type="number"
                      placeholder="Min Price"
                      value={filters.price_low || ''}
                      onChange={(e) => { handlePriceChange('price_low', e.target.value) }}
                      className="w-1/2 px-2 py-1 border rounded"
                      onClick={(e) => { e.stopPropagation() }}
                    />
                    <input
                      type="number"
                      placeholder="Max Price"
                      value={filters.price_high || ''}
                      onChange={(e) => { handlePriceChange('price_high', e.target.value) }}
                      className="w-1/2 px-2 py-1 border rounded"
                      onClick={(e) => { e.stopPropagation() }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="relative h-full flex items-center" ref={sizeDropdownRef}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSizeDropdown(!showSizeDropdown);
                  setShowPriceDropdown(false);
                }}
                className="h-full px-2 flex items-center hover:bg-gray-50"
              >
                <span className="text-xs">
                  {filters.sqft_low ? `${filters.sqft_low}+ sqft` : 'Any Size'}
                </span>
              </button>

              {showSizeDropdown && (
                <div className="absolute top-full right-0 mt-1 w-48 bg-white border rounded-lg shadow-lg p-2 z-50">
                  {sizeOptions.map((option) => (
                    <button
                      key={option.value}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSizeChange(option.value);
                      }}
                      className={`w-full text-left px-2 py-2 hover:bg-gray-100 rounded ${filters.sqft_low === option.value ? 'bg-primary-10' : ''}`}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <button className="h-full px-2 flex items-center" onClick={handleSearchClick}>
              <SearchIcon />
            </button>
          </div>

          <div className="hidden xl:relative xl:block h-full ml-2">
            <button
              onClick={handleSaveSearch}
              className="h-full px-2 flex items-center border rounded-lg hover:bg-primary-10 text-xs"
            >
              Save Search
            </button>
          </div>
        </div>

        <div className="flex flex-1 items-center justify-end gap-2">
          <button
            ref={advancedFiltersButtonRef}
            onClick={() => {
              setShowAdvancedFilters(!showAdvancedFilters);
            }}
            className="p-2 text-primary rounded-lg hover:bg-primary-10"
          >
            <FilterIcon />
          </button>

          {showAdvancedFilters && (
            <div
              className="fixed inset-0 bg-black/20 z-[50]"
              onClick={() => { setShowAdvancedFilters(false) }}
            >
              <div
                className="fixed left-1/2 top-20 transform -translate-x-1/2 z-[60]"
                onClick={(e) => e.stopPropagation()}
              >
                <Filters
                  setShowFilters={setShowAdvancedFilters}
                  visible={showAdvancedFilters}
                  buttonRef={advancedFiltersButtonRef}
                />
              </div>
            </div>
          )}

          <button
            className="hidden xl:block px-2 h-10 border border-primary text-primary rounded-lg hover:bg-primary-10 text-sm xl:text-base whitespace-nowrap"
            onClick={handleMyBoardsClick}
          >
            My boards
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
