// SquareFeetNew.tsx
import React from "react";

const SquareFeetNew: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.66602 9.16602H29.3327" stroke="currentColor" strokeWidth="2" />
    <path
      d="M2.66602 7.16602L2.66602 11.166"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M29.332 7.16602L29.332 11.166"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      width="26.6667"
      height="10.6667"
      rx="1.33333"
      transform="matrix(1 0 0 -1 2.66602 26.5)"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M2.66602 18.5H29.3327" stroke="currentColor" strokeWidth="2" />
    <path
      d="M6 22.5L6 26.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M10 22.5L10 26.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14 22.5L14 26.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M18 22.5L18 26.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M22 22.5L22 26.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M26 22.5L26 26.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default SquareFeetNew;
