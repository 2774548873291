import React from 'react';

const FilterIcon = ({ className = "pointer-events-none" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.125 3.75H22.3125M6.375 3.75H1.6875M10.125 18.25H22.3125M6.375 18.25H1.6875M17.625 11H22.3125M13.875 11H1.6875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8.25" cy="3.75" r="1.5" fill="currentColor" />
      <circle cx="8.25" cy="18.25" r="1.5" fill="currentColor" />
      <circle cx="15.75" cy="11" r="1.5" fill="currentColor" />
    </svg>
  );
};

export default FilterIcon;