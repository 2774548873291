import React, { createContext, useState, useEffect, useContext } from 'react';
import { useProperties } from '../../hooks/useProperties';
import { type Property, type Filters, type PropertyContextType } from "../../types/types";

const defaultContext: PropertyContextType = {
  properties: [],
  filters: {
    price_low: undefined,
    sw: undefined,
    ne: undefined,
  },
  meta: { total_count: 0 },
  setProperties: () => { },
  setFilters: () => { },
  clearFilters: () => { },
};

export const PropertyContext = createContext<PropertyContextType>(defaultContext);

export const PropertyContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const [filters, setFilters] = useState<Filters>({});
  const { properties, meta } = useProperties(filters);

  const sendToListhub = (props: Property[]) => {

    const listhubProperties = props.reduce((arr: Array<{ lkey: string }>, prop) => {
      if (prop.listhub_key) {
        arr.push({ lkey: prop.listhub_key });
      }
      return arr;
    }, []);
    if (typeof window.lh === 'function') {
      window.lh('submit', 'SEARCH_DISPLAY', listhubProperties);
    }
  };

  useEffect(() => {
    sendToListhub(properties);
  }, [properties]);

  const clearFilters = () => {
    setFilters({
      ne: filters.ne,
      sw: filters.sw,
      zoom: filters.zoom,
      lat: filters.lat,
      lng: filters.lng,
      city: filters.city,
      state: filters.state,
      activeTab: filters.activeTab,
      price_high: '',
      price_low: '',
      bedrooms: '',
      bathrooms: '',
      home_type: [],
      sqft_high: '',
      sqft_low: '',
      lot_size_high: '',
      lot_size_low: '',
      year_built_high: '',
      year_built_low: '',
      listing_source: [],
    });
  };

  const setProperties = (newProps: Property[]) => {
  };

  return (
    <PropertyContext.Provider
      value={{
        properties,
        filters,
        meta,
        setProperties,
        setFilters,
        clearFilters,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

export const usePropertyContext = () => {
  const ctx = useContext(PropertyContext);
  if (!ctx) {
    throw new Error('usePropertyContext must be used within a PropertyContextProvider');
  }
  return ctx;
};
