import React, { useEffect } from 'react';
import useMap from '../../hooks/useSetupMap';

interface MapProps {
  center: { lat: number; lng: number; };
  visible: boolean;
}

const Map: React.FC<MapProps> = ({ center, visible }) => {

  useEffect(() => {
    if (visible && window.innerWidth < 1280 ) document.body.style.overflow = "hidden"
    else document.body.style.overflow = "auto"
    return () => { document.body.style.overflow = "auto" }
  }, [visible, window.innerWidth]);

  useMap(center);

  const mobileClasses = visible ? 'mobile:visible mobile:relative' : 'mobile:invisible mobile:absolute';

  return (
    <div className={`h-[calc(100vh-185px)] xl:h-[calc(100vh-200px)] relative w-full m-0 xl:w-1/2 lg:mr-4 rounded-lg order-2 ${mobileClasses}`} >
      <div id="map-container" className="w-full h-full rounded-lg"></div>
    </div>
  );
};

export default Map;