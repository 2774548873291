import React, { useRef, useEffect, useCallback, useState } from 'react'
import Button from '../../components/Button'
import CheckBoxColumns from '../../components/CheckBoxColumns'
import HorizontalRadio from '../../components/horizontal-radio'
import RangeInput from '../../components/range-input'
import { usePropertyContext } from '../context/property-context-v2'
import useOnClickOutside from '../../hooks/onClickOutside'

interface Props {
  setShowFilters: (visible: boolean) => void
  visible: boolean
  buttonRef: React.MutableRefObject<HTMLButtonElement | undefined>
}

const Filters: React.FunctionComponent<Props> = ({ setShowFilters, buttonRef }) => {
  const [localFilters, setLocalFilters] = useState<any>({})
  const { filters, setFilters, clearFilters } = usePropertyContext()
  const parentRef = useRef<HTMLDivElement>(null)

  const homeTypes = [
    { value: 'house', label: 'House' },
    { value: 'multi_family', label: 'Multi-Family' },
    { value: 'apartment', label: 'Apartment' },
    { value: 'manufactured', label: 'Manufactured' },
    { value: 'town_home', label: 'Town Home' },
    { value: 'condo', label: 'Condo' },
    { value: 'lot', label: 'Lot' }
  ]

  const listingSources = [
    { value: 'otto', label: 'Otto Exclusive' },
    { value: 'fsbo', label: 'For Sale By Owner' },
    { value: 'mls', label: 'Agent Listing' }
  ]

  useEffect(() => {
    setLocalFilters({ ...filters })
  }, [filters])

  const applyFilters = useCallback(() => {
    setFilters((prevFilters) => ({ ...prevFilters, ...localFilters }))
    setShowFilters(false)
  }, [localFilters, setFilters, setShowFilters])

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (window.innerWidth > 1279 && buttonRef.current && event.target !== buttonRef.current) {
        applyFilters()
      }
    },
    [buttonRef, applyFilters]
  )

  useOnClickOutside(parentRef, handleClickOutside)

  const updateFilter = useCallback((name: string, value: any) => {
    setLocalFilters((prev) => ({ ...prev, [name]: value }))
  }, [])

  return (
    <div
      ref={parentRef}
      className="flex flex-col w-full max-h-[85vh] overflow-y-auto bg-white p-2 sm:p-2 md:p-3 lg:p-3 xl:p-4 border border-slate-300 rounded-lg drop-shadow-md font-montserrat text-black text-sm md:text-base"
    >
      <div className="flex justify-between items-center mb-2 sm:mb-3 md:mb-3 lg:mb-4">
        <h2 className="text-lg md:text-xl font-semibold text-black">Filters</h2>
        <button
          onClick={() => setShowFilters(false)}
          className="text-gray-500 hover:text-gray-700"
        >
          ✖
        </button>
      </div>

      <div className="flex flex-col xl:flex-row xl:gap-6 space-y-3 sm:space-y-3 md:space-y-4 lg:space-y-5 xl:space-y-6">
        <div className="xl:w-1/2">
          <div className="w-full mb-2 sm:mb-3 md:mb-3 lg:mb-4">
            <RangeInput
              name_prefix="price"
              label="Price Range"
              rangeHigh={localFilters.price_high}
              rangeLow={localFilters.price_low}
              onChange={updateFilter}
              labelClassName="text-black"
            />
          </div>

          <div className="w-full mb-2 sm:mb-3 md:mb-3 lg:mb-4">
            <HorizontalRadio
              name="bedrooms"
              label="Bedrooms"
              selected={localFilters.bedrooms}
              onClick={updateFilter}
              labelClassName="text-black"
            />
          </div>

          <div className="w-full mb-2 sm:mb-3 md:mb-3 lg:mb-4">
            <HorizontalRadio
              name="bathrooms"
              label="Bathrooms"
              selected={localFilters.bathrooms}
              onClick={updateFilter}
              labelClassName="text-black"
            />
          </div>

          <div className="w-full hidden sm:block mb-2 sm:mb-3 md:mb-3 lg:mb-4">
            <CheckBoxColumns
              name="home_type"
              selected={localFilters.home_type || []}
              options={homeTypes}
              onChange={updateFilter}
              label="Home Types"
              labelClassName="text-black"
            />
          </div>
        </div>

        <div className="xl:w-1/2">
          <div className="w-full mb-2 sm:mb-3 md:mb-3 lg:mb-4">
            <RangeInput
              name_prefix="sqft"
              label="Square Feet"
              rangeHigh={localFilters.sqft_high}
              rangeLow={localFilters.sqft_low}
              onChange={updateFilter}
              labelClassName="text-black"
            />
          </div>

          <div className="w-full hidden sm:block mb-2 sm:mb-3 md:mb-3 lg:mb-4">
            <RangeInput
              name_prefix="lot_size"
              label="Lot Size"
              rangeHigh={localFilters.lot_size_high}
              rangeLow={localFilters.lot_size_low}
              onChange={updateFilter}
              labelClassName="text-black"
            />
          </div>

          <div className="w-full hidden sm:block mb-2 sm:mb-3 md:mb-3 lg:mb-4">
            <RangeInput
              name_prefix="year_built"
              label="Year Built"
              rangeHigh={localFilters.year_built_high}
              rangeLow={localFilters.year_built_low}
              onChange={updateFilter}
              labelClassName="text-black"
            />
          </div>

          <div className="hidden lg:block w-full">
            <CheckBoxColumns
              name="listing_source"
              selected={localFilters.listing_source || []}
              options={listingSources}
              onChange={updateFilter}
              label="Listing Type"
              labelClassName="text-black"
            />
          </div>
        </div>
      </div>

      <div className="my-2 sm:my-3 md:my-3 lg:my-4 flex justify-between items-center border-t pt-2 sm:pt-3 md:pt-3 lg:pt-4">
        <Button
          classNames="hover:bg-primary-70 text-base border border-primary text-primary w-32 py-2"
          onClick={clearFilters}
        >
          Clear All
        </Button>
        <Button
          classNames="hover:bg-primary-70 text-base w-32 bg-primary text-white py-2"
          onClick={applyFilters}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

export default Filters