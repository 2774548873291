import React from 'react';

interface HouseProps {
  width?: number;
  height?: number;
  className?: string;
}

const House: React.FC<HouseProps> = ({ width = 25, height = 25, className }) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 25 25" 
      fill="none" 
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M22.4872 21.8689H2.51286C1.85924 21.8689 1.32935 21.339 1.32935 20.6855V10.8116C1.32935 10.4112 1.53179 10.038 1.86749 9.81956L11.8546 3.32231C12.2471 3.06704 12.753 3.06704 13.1454 3.32231L23.1326 9.81956C23.4682 10.038 23.6707 10.4112 23.6707 10.8116V20.6855C23.6707 21.339 23.1408 21.8689 22.4872 21.8689Z" 
        stroke="currentColor" 
        strokeWidth="1.91431"
      />
    </svg>
  );
};

export default House;