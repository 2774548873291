import React from "react";

const BathNew: React.FunctionComponent<{ className?: string }> = ({ className }) => (
  <svg
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.66406 17.5002C1.66406 20.4457 4.05188 22.8335 6.9974 22.8335H22.9974C25.9429 22.8335 28.3307 20.4457 28.3307 17.5002V12.1668H1.66406V17.5002Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M1.66406 14.8335H28.3307" stroke="currentColor" strokeWidth="2" />
    <path d="M6.99674 22.8333L4.33008 25.4999" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M22.9974 22.8335L25.6641 25.5002" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M25.6641 12.1667V5.5C25.6641 3.29086 23.8732 1.5 21.6641 1.5V1.5C19.4549 1.5 17.6641 3.29086 17.6641 5.5V5.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M20.3314 8.16675C20.3314 7.81656 20.2624 7.46979 20.1284 7.14626C19.9944 6.82272 19.798 6.52875 19.5503 6.28113C19.3027 6.03351 19.0087 5.83708 18.6852 5.70307C18.3617 5.56906 18.0149 5.50008 17.6647 5.50008C17.3145 5.50008 16.9678 5.56906 16.6442 5.70307C16.3207 5.83708 16.0267 6.03351 15.7791 6.28113C15.5315 6.52875 15.335 6.82272 15.201 7.14626C15.067 7.46979 14.998 7.81656 14.998 8.16675L17.6647 8.16675H20.3314Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default BathNew;
