import React from 'react';

interface OvalMarkerProps {
  primaryColor: string;
}

const OvalMarker: React.FC<OvalMarkerProps> = ({ primaryColor }) => {
  return (
    <svg width="80" height="28" viewBox="0 0 80 28" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1.5"/>
          <feOffset dx="0" dy="2" result="offsetblur"/>
          <feFlood floodColor="rgba(0,0,0,0.15)"/>
          <feComposite in2="offsetblur" operator="in"/>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <rect
        x="2"
        y="2"
        width="76"
        height="24"
        rx="12"
        ry="12"
        fill="white"
        filter="url(#shadow)"
        style={{ stroke: 'none' }}
      />
    </svg>
  );
};

export default OvalMarker;