import React from 'react';
import PreFilters from './PreFilters';

const BottomBar: React.FC = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-[0_-4px_12px_rgba(0,0,0,0.15)] xl:hidden z-[9999]">
      <div className="container mx-auto px-4 py-3 flex justify-center">
        <PreFilters />
      </div>
    </div>
  );
};

export default BottomBar;