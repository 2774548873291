import React, { useMemo } from 'react';
import { type Property } from "../../types/types";
import DollarSign from '../../../icons/dollar_sign';

interface Props {
  property: Property;
}

const PriceCardNew: React.FunctionComponent<Props> = ({ property }) => {
  const label = useMemo(() => {
    if (property.listing_source === 'otto' && property.suggested_bid) {
      return 'Suggested Bid';
    } else {
      return 'List Price';
    }
  }, [property]);

  const price = useMemo(() => {
    if (property.listing_source === 'otto' && property.suggested_bid) {
      return property.suggested_bid;
    } else {
      return property.selling_price;
    }
  }, [property]);

  return (
    <div className="border-primary border rounded-md py-1.5 lg:py-2 px-2 w-[90%] mb-4 flex justify-center items-center mx-auto">
      <div className="flex items-center text-primary truncate">
        <DollarSign className="w-4 lg:w-5 h-4 lg:h-5 flex-shrink-0" />
        <p className="text-primary text-sm lg:text-lg font-semibold ml-1.5 lg:ml-2 truncate">
          {label}: {price?.toLocaleString()}
        </p>
      </div>
    </div>
  );
};

export default PriceCardNew;