import React from 'react';
import type { ChangeEvent } from 'react';

interface Props {
  options: { label: string; value: string }[];
  name: string;
  onChange: (name: string, value: string[]) => void;
  selected: any;
  label: string;
  columns?: number;
  labelClassName?: string;
}

const CheckBoxColumns = ({ options, name, selected, onChange, label, columns = 2, labelClassName }: Props) => {
  const onChoice = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const selectedOpts = [...selected];
    const index = selectedOpts?.indexOf(value);
    if (index > -1) {
      selectedOpts.splice(index, 1);
    } else {
      selectedOpts.push(value);
    }
    onChange(event.target.name, selectedOpts);
  };

  const inputs = options.map(({ label, value }) => (
    <div key={value} className="flex items-center">
      <input
        className="mr-1"
        onChange={onChoice}
        name={name}
        value={value}
        type="checkbox"
        checked={selected?.includes(value)}
      />
      <label className={`text-black ${labelClassName || ''}`} htmlFor="">
        {label}
      </label>
    </div>
  ));

  return (
    <div className="flex flex-col">
      <label className={`text-black font-semibold ${labelClassName || ''}`}>{label}</label>
      <div className={`grid grid-cols-${columns} gap-4 mt-2`}>{inputs}</div>
    </div>
  );
};

export default CheckBoxColumns;
