import React from 'react';
import { usePropertyContext } from '../context/property-context-v2';
import House from '../../components/icons/House';
import ForSale from '../../components/icons/ForSale';

const PreFilters = () => {
  const { filters, setFilters, clearFilters } = usePropertyContext();

  const tabs = [
    {
      id: 'all',
      label: 'All Homes',
      Icon: House,
      action: () => {
        clearFilters();
        setFilters(prev => ({ ...prev, activeTab: 'all' }));
      }
    },
    {
      id: 'new',
      label: 'Newly Listed',
      Icon: ForSale,
      action: () => {
        setFilters(prev => ({ ...prev, activeTab: 'new', sortBy: 'date' }));
      }
    },
  ];

  return (
    <nav className="flex items-center justify-start space-x-6 h-10">
      {tabs.map(tab => {
        const Icon = tab.Icon;
        return (
          <button
            key={tab.id}
            onClick={tab.action}
            className={`flex flex-col items-center gap-1 px-1 py-1 rounded-lg transition-colors ${filters.activeTab === tab.id ? 'text-primary border-b-2 border-primary' : 'text-gray-600 hover:text-gray-900'} `}
        >
            <Icon className="w-4 h-4" />
            <span className='text-xs'>{tab.label}</span>
          </button>
        );
      })}
    </nav>
  );
};

export default PreFilters;