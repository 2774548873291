import React, { useMemo, useEffect, useRef, useState } from "react";
import placeholderImage from "../../../images/placeholder-image.png";
import { type Property } from "../../types/types";
import OttoBanner from "./OttoBannerV2";
import OtherBanner from "./OtherBannerV2";
import FsboBanner from "./FsboBannerV2";
import PriceCardNew from "./PriceCardV2";
import BedNew from "../../components/icons/BedNew";
import BathNew from "../../components/icons/BathNew";
import SquareFeetNew from "../../components/icons/SquareFeetNew";

interface Props {
  property: Property;
}

const ListingNew: React.FunctionComponent<Props> = ({ property }) => {
  const [inView, setInView] = useState(false);
  const listingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, obs) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setInView(true);
          obs.disconnect();
        }
      }
    }, {});
    if (listingRef.current) {
      observer.observe(listingRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  const titleize = (str: string) => {
    if (!str) return '';
    return str.toLowerCase().split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  const getAddressDisplay = () => {
    if (property.street_address) {
      return (
        <>
          <p className="text-sm md:text-lg lg:text-xl truncate px-1">
            {titleize(property.street_address)}
          </p>
          <p className="text-sm truncate">
            {titleize(property.city)} - {titleize(property.state)}
          </p>
        </>
      );
    }

    return (
      <>
        <p className="text-sm md:text-lg lg:text-xl truncate px-1">
          Property available in:
        </p>
        <p className="text-sm truncate">
          {titleize(property.city)} - {titleize(property.state)}
        </p>
      </>
    );
  };

  const addressHTML = useMemo(
    () => (
      <div className="font-montserrat font-semibold text-black text-center mt-6 w-full overflow-hidden">
        {getAddressDisplay()}
      </div>
    ),
    [property]
  );

  const defaultBg = `url(${placeholderImage})`;
  const background = property.cover_photo ? `url(${property.cover_photo})` : defaultBg;
  const currentBackground = inView ? background : defaultBg;

  const banner =
    property.listing_source === "otto" ? (
      <OttoBanner />
    ) : property.listing_source === "fsbo" ? (
      <FsboBanner />
    ) : (
      <OtherBanner />
    );

  return (
    <div className="w-full sm:w-1/2 pb-6 pr-6" ref={listingRef}>
      <div className="border border-grayalt rounded-lg p-4 flex flex-col justify-between items-center">
        <a className="block w-full" href={`/properties/${property.id}`}>
          <div
            style={{ backgroundImage: currentBackground }}
            className="h-56 bg-cover bg-center rounded-lg relative mb-4"
          >
            <div className="absolute -left-8">
              <div className="relative flex items-center">
                {banner}
                <span
                  className="absolute -bottom-[9.3px] left-[2px] w-0 h-0 border-solid"
                  style={{ borderWidth: "0 11px 10px 3px", borderColor: "transparent var(--primary-color-dark) transparent transparent" }}
                ></span>
              </div>
            </div>
          </div>

          <div>
            {addressHTML}
            <div className="text-sm text-gray-600 mt-2 flex flex-nowrap items-center justify-center gap-4 mb-4">
              <div className="flex items-center whitespace-nowrap">
                <BedNew className="w-4 h-4 mr-1" />
                <span className="text-xs lg:text-xs">{property.bedrooms} Bed</span>
              </div>
              <div className="flex items-center whitespace-nowrap">
                <BathNew className="w-4 h-4 mr-1" />
                <span className="text-xs lg:text-xs">{property.bathrooms} Bath</span>
              </div>
              <div className="flex items-center whitespace-nowrap">
                <SquareFeetNew className="w-4 h-4 mr-1" />
                <span className="text-xs lg:text-xs">{property.square_feet} Sqft</span>
              </div>
            </div>
            <PriceCardNew property={property} />
          </div>
        </a>
      </div>
    </div>
  );
};

export default ListingNew;