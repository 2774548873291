import React from 'react'
import { usePropertyContext } from '../context/property-context-v2';

const HomeTitle = () => {
  const { filters } = usePropertyContext();
  return (
    <h2 className="hidden xl:block text-4xl font-bold font-montserrat my-4 text-letterBlack xl:pl-6">
      {filters.activeTab === 'new' ? 'Newly Listed' : 'All Homes'}
    </h2>
  );
};

export default HomeTitle