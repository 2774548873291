import { useState, useEffect } from 'react';

interface SessionState {
  isLoggedIn: boolean;
  userId?: number;
}

export function useSession() {
  const [session, setSession] = useState<SessionState>({ isLoggedIn: false });

  useEffect(() => {
    async function fetchSessionStatus() {
      try {
        const response = await fetch('/session_status', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        if (response.ok) {
          const data = await response.json();
          setSession(data);
        } else {
          setSession({ isLoggedIn: false });
        }
      } catch (error) {
        console.error('Error fetching session status:', error);
        setSession({ isLoggedIn: false });
      }
    }

    fetchSessionStatus();
  }, []);

  return session;
}
