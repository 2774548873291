import React from 'react'
import Map from '../../components/icons/Map'
import List from '../../components/icons/List'

interface Props {
  toggleMapVisibility: () => void
  mapVisible: boolean
}

const MobileControlsV2: React.FunctionComponent<Props> = ({toggleMapVisibility, mapVisible}) => {
  return (
    <button 
      className='h-10 flex justify-center items-center bg-white text-primary border border-primary rounded-lg px-3' 
      onClick={toggleMapVisibility}
    >
      <span className='mr-2 hidden sm:block'>
        {mapVisible ? <List className="w-5 h-5" /> : <Map className="w-5 h-5" />}
      </span>
      <span>{mapVisible ? 'List' : 'Map'}</span>
    </button>
  )
}

export default MobileControlsV2;