// Bed.tsx
import React from 'react';

interface IconProps {
  className?: string;
}

const Bed: React.FC<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 34 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.99805 13.5002C1.99805 11.8434 3.34119 10.5002 4.99805 10.5002H28.998C30.6549 10.5002 31.998 11.8434 31.998 13.5002V22.5002H1.99805V13.5002Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinejoin="round"
    />
    <path d="M1.99805 18.0002H31.998" stroke="currentColor" strokeWidth="2.25" />
    <path
      d="M4.99805 4.50024C4.99805 2.84339 6.34119 1.50024 7.99805 1.50024H25.998C27.6549 1.50024 28.998 2.84339 28.998 4.50024V10.5002H4.99805V4.50024Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinejoin="round"
    />
    <path
      d="M9.49805 7.50024C9.49805 6.67182 10.1696 6.00024 10.998 6.00024H13.998C14.8265 6.00024 15.498 6.67182 15.498 7.50024V10.5002H9.49805V7.50024Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinejoin="round"
    />
    <path
      d="M18.498 7.50024C18.498 6.67182 19.1696 6.00024 19.998 6.00024H22.998C23.8265 6.00024 24.498 6.67182 24.498 7.50024V10.5002H18.498V7.50024Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinejoin="round"
    />
    <path
      d="M4.99805 22.5005V26.2505"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinecap="round"
    />
    <path
      d="M28.998 22.5002V26.2502"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinecap="round"
    />
  </svg>
);

export default Bed;
