import React, { useContext } from "react";
import { PropertyContext } from '../context/property-context-v2';
import { PropertyList } from "./PropertyList";

interface Props { visible: boolean }

const ListingsNew: React.FunctionComponent<Props> = ({ visible }) => {
  const { properties } = useContext(PropertyContext);
  const mobileVisibilityClasses = visible ? "mobile:visible" : "mobile:invisible mobile:absolute";

  return (
    <div className={`flex flex-wrap w-full xl:w-[50%] pl-6 px-1 lg:px-1 lg:pl-4 h-full overflow-y-auto overflow-x-hidden pb-4 mobile:pt-4 ${mobileVisibilityClasses}`}>
      {properties.length ? (<PropertyList properties={properties} />) :
        <h2 className="font-montserrat">No matching properties found in this area</h2>}
    </div>
  );
};

export default ListingsNew;