import React from 'react';
import Bed from '../../components/icons/Bed';
import Bath from '../../components/icons/Bath';
import SquareFeet from '../../components/icons/SquareFeet';
import placeholderImage from '../../../images/placeholder-image.png';

interface Property {
  id: string | number;
  cover_photo?: string;
  selling_price?: number;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  bedrooms: number;
  bathrooms: number;
  square_feet: number;
}

interface PopupContentProps {
  property: Property;
  primaryColor: string;
}

const PopupContent: React.FC<PopupContentProps> = ({ property, primaryColor }) => (
  <div className="w-60 shadow-xl">
    <div className="relative">
      <img 
        className="w-full h-40 object-cover" 
        src={property.cover_photo || placeholderImage} 
        alt="Property" 
      />
    </div>
    <div className="px-2 py-2">
      <p className="text-lg font-semibold capitalize">
        {property?.selling_price?.toLocaleString('en-US') || 'Unknown'}
      </p>
      <p className="text-gray-600 capitalize">{property.street_address}</p>
      <p className="text-gray-600 capitalize">
        {property.city}, {property.state}, {property.zip_code}
      </p>
      <div className="mt-2 flex items-center">
        <div className="mr-2 flex items-center">
          <Bed color={primaryColor} />
          <span className="ml-1">{property.bedrooms} bd</span>
        </div>
        <div className="mr-2 flex items-center">
          <Bath color={primaryColor} />
          <span className="ml-1">{property.bathrooms} ba</span>
        </div>
        <div className="flex items-center">
          <SquareFeet color={primaryColor} />
          <span className="ml-1">{property.square_feet} sqft</span>
        </div>
      </div>
      <div className="mt-4">
        <a 
          href={`/properties/${property.id}`}
          className="inline-block rounded-md focus:outline-none bg-primary px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-cyan"
        >
          View Property
        </a>
      </div>
    </div>
  </div>
);

export default PopupContent;